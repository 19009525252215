
import PeerGroupForm from '@/components/Settings/PeerGroup/Form.vue';
export default {
  components: { PeerGroupForm },
  computed: {
    peerGroupId() {
      return this.$route.query.id as string;
    }
  }
};
